import { createWebHashHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../app.config.json";
import store from '@/state/store';

const router = createRouter({
    history: createWebHashHistory("/"),
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, left: 0 };
        }
    },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    if (routeTo.matched.length < 1) {
        next(false);
        router.push('/');
    } else {
        next();
    }
    if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {

        // Check if auth is required on this route
        // (including nested routes).
        const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

        // If auth isn't required for the route, just continue.
        if (!authRequired) return next();
        // If auth is required and the user is logged in...
        if (store.getters['auth/loggedIn']) {
            // Validate the local user token...
            return store.dispatch('auth/validate').then((validUser) => {
                // Then continue if the token still represents a valid user,
                // otherwise redirect to login.
                validUser ? next() : redirectToLogin();
            });
        }

        // If auth is required and the user is NOT currently logged in,
        // redirect to login.
        redirectToLogin();

        // eslint-disable-next-line no-unused-vars
        // eslint-disable-next-line no-inner-declarations
        function redirectToLogin() {
            // Pass the original route to the login component
            next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
        }
    } 
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }
    document.title = routeTo.meta.title + ' | ' + appConfig.title;
    // If we reach this point, continue resolving the route.
    next();
});

export default router;