import { url } from "../../App.vue";
// import * as jwt from 'jsonwebtoken'

export const userService = {
    login,
    logout,
};

// Async Login function call when "Login" is clicked
async function login(email, password, body) {

    // Hash password entered
    var sha256 = require('js-sha256');
    var hash = sha256.create()
    hash.update(password)
    hash.hex()

    // Fetch from the Express Back-end, the matching account on LASER based on credentials entered
    const data = await fetch(`${url}/login/${email},${hash}`, {
        method: "GET",
        headers: {
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        body: body && JSON.stringify(body),
        withCredentials: true, 
        credentials: 'include' 
    })
    var user = ""
    await data.json().then(response => user = response)

    // Return promise whether or not credentials match
    return new Promise((resolve, reject) => {
        if (user === "No matching username/password found.") {
            reject(user)
        }else if (user === "No Fusion Team found.") {
            reject("Error logging in, user has no Fusion Team assigned.")
        }else if(user === "Successfully logged in."){
            localstorage()
            resolve(JSON.stringify(user))
        }else{
            reject("No matching username/password found.")
        }
    })
}

function containsFusion(words) {
    if (!words) {
        return false;
    }

    if (typeof words === 'string') {
        words = words.indexOf(',') !== -1 ? words.split(',') : [words];
    } else if (Array.isArray(words)) {
        // words is already an array
    } else if (typeof words === 'object') {
        words = Object.values(words);
    } else {
        return false;
    }

    return words.some(word => String(word).toLowerCase() === "fusion");
}


async function localstorage(body) {
    try {
        // Fetch data from server
        const data = await fetch(`${url}/setLocalStorage`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: body && JSON.stringify(body),
            withCredentials: true, 
            credentials: 'include' 
        });
        
        if (!data.ok) {
            throw new Error("Failed to fetch data");
        }

        const userdata = await data.json();
        // console.log("Fetched userdata:", userdata);

        // Set local storage
        window.localStorage.setItem("title", userdata[0]);
        window.localStorage.setItem("full_name", userdata[1]);
        window.localStorage.setItem("type", userdata[2]);
        window.localStorage.setItem("additional_services", userdata[3]);
        window.localStorage.setItem("preferred_direction", userdata[4]);
        window.localStorage.setItem("list", userdata[6]);
        window.localStorage.setItem("logo", userdata[7]);
        window.localStorage.setItem("country", "");

        // Parse and log customer data
        const customer_data = JSON.parse(userdata[6]);
        // console.log("Parsed customer_data:", customer_data);

        customer_data.forEach(element => {
            // console.log("Setting division for:", element.customer);
            window.localStorage.setItem("division", element.customer);
        });

        if (containsFusion(userdata[3])) {
            window.localStorage.setItem("all_divisions", userdata[8]);
        }

        // Log IP address
        const userid = userdata[5];
        await fetch(`${url}/log_ip/${userid}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: body && JSON.stringify(body),
            withCredentials: true, 
            credentials: 'include' 
        });
    } catch (error) {
        // console.error("Error in localstorage function:", error);
    }
}



async function logout(body) {
    const data = await fetch(`${url}/logout`, {
        method: "GET",
        headers: {
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        body: body && JSON.stringify(body),
        withCredentials: true, 
        credentials: 'include' 
    })
    await data.json()

}
